import * as React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Global/Head"
import VerifyEmail from "../components/Sections/VerifyEmail";

const VerifyEmailPage = () => {
    return (
        <Layout backgroundImage="" hideLanding={true} unverifiedUserEmail={true}>
            <Head
                pageTitle="Platforma Treningowa Online"
                description=""
                keywords="trening online, platforma treningowa online, video trening"
            />
            <VerifyEmail />
        </Layout>
    )
}

export default VerifyEmailPage
