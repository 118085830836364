import * as React from "react"
import {VerifyEmailContainer} from "./styles"

const VerifyEmail = () => {
    return (
        <VerifyEmailContainer>
            <h2>Please verify your email.</h2>
        </VerifyEmailContainer>
    )
}

export default VerifyEmail